<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid class="fill-height pa-0">
        <v-row class="fill-height" align="center" justify="center">
          <v-col
            class="fill-height d-flex justify-center accent pa-6"
            :class="{
              'align-center': $vuetify.breakpoint.mdAndUp,
              'align-start': $vuetify.breakpoint.mdAndDown,
            }"
            cols="12"
            md="6"
            style="position: relative"
          >
            <!-- <canvas ref="my-canvas"></canvas> -->
            <v-card
              class="pa-4 pa-md-8"
              width="100%"
              max-width="450px"
              color="accent"
              flat
            >
              <v-card-title
                class="pt-0 px-0 pb-3 justify-center flex-column"
                style="width: 100%"
              >
                <div class="text-md-h4 text-h5 primary--text mt-2">
                  <strong style="letter-spacing: 4px !important">
                    Efetue o Login
                  </strong>
                </div>
              </v-card-title>
              <v-card-text class="pa-0 mt-4">
                <v-form v-model="valid" ref="form">
                  <v-combobox
                    v-model="selectedConnection"
                    label="Evento"
                    solo
                    flat
                    :items="eventConnections"
                    required
                    height="56px"
                  ></v-combobox>

                  <v-text-field
                    label="Email"
                    name="email"
                    prepend-inner-icon="mdi-account"
                    solo
                    flat
                    full-width
                    type="email"
                    v-model="login.email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>

                  <v-text-field
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    name="senha"
                    label="Senha"
                    prepend-inner-icon="mdi-lock"
                    full-width
                    solo
                    flat
                    v-model="login.senha"
                    class="input-group--focused mt-1"
                    @click:append="show = !show"
                    @keyup.enter="logar"
                  ></v-text-field>

                  <div>
                    <v-checkbox
                      class="ma-0"
                      color="xbColor"
                      v-model="checkbox"
                      label="Lembrar Email"
                    >
                      <!-- <template v-slot:label>
                        <div
                          class="body-2 font-weight-bold secondary--text d-block"
                          style="width: 100% !important;"
                        >
                          lembrar email
                        </div>
                      </template> -->
                    </v-checkbox>
                  </div>
                </v-form>
              </v-card-text>

              <template v-if="erros.length > 0">
                <v-card-text class="pa-0 mb-4 red--text">
                  <div class="py-1" v-for="(erro, index) in erros" :key="index">
                    <v-icon class="mr-2 red--text">mdi-alert-circle</v-icon>
                    <span>{{ erro }}</span>
                  </div>
                </v-card-text>
              </template>

              <v-card-actions class="d-flex justify-end px-0">
                <v-btn
                  @click.prevent="logar"
                  x-large
                  max-height="50px"
                  color="xbColor"
                  class="mt-1 px-8 white--text"
                  :disabled="!valid"
                  :loading="loading"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            class="fill-height primary d-flex justify-center align-center pl-8"
            cols="12"
            md="6"
          >
            <div class="d-block">
              <div style="position: relative">
                <v-img
                  src="@/assets/Logo.png"
                  max-width="450px"
                  max-height="80%"
                  contain
                >
                </v-img>
              </div>
              <div>
                <v-img
                  src="@/assets/logo-escrita-2.png"
                  max-width="450px"
                  max-height="80%"
                  contain
                >
                </v-img>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
// import { basenodes } from "@/plugins/basenodes.js";

export default {
  name: "Login",

  data() {
    return {
      show: false,
      valid: false,
      loading: false,
      loadingLanguage: false,
      checkbox: false,
      login: {
        email: "",
        senha: "",
        event_connection: "xbusiness_julho_2024",
      },
      erros: [],
      rules: {
        required: (value) => !!value || "obrigatório",
        min: (v) => v.length >= 3 || "min 3 caracteres",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "email inválido";
        },
      },
      selectedConnection: {
        text: "XBUSINESS JULHO - 2024",
        value: "xbusiness_julho_2024",
      },
      eventConnections: [
        {
          text: "XBUSINESS JULHO - 2024",
          value: "xbusiness_julho_2024",
        },
        {
          text: "XBUSINESS MARCO - 2024",
          value: "xb_marco_2024",
        },
        {
          text: "XBUSINESS BALNEÁRIO CAMBORIÚ - 2023",
          value: "xbusiness_balneario_setembro_2023",
        },
        {
          text: "XBUSINESS MARINGÁ - 2023",
          value: "xb_mar_junho_2023",
        },
        {
          text: "XBUSINESS CURITIBA - 2023",
          value: "xb_cur_fevereiro_2023",
        },
        {
          text: "XBUSINESS CASCAVEL - 2022",
          value: "xbusiness_novembro_2022",
        },
        {
          text: "XBUSINESS MARINGÁ - 2022",
          value: "xbusiness_setembro_2022",
        },
        {
          text: "XBUSINESS MARÇO - 2022",
          value: "xbusiness_marco_2022",
        }
      ],
    };
  },

  computed: {
    ...mapState("Usuario", {
      role: (state) => state.role,
    }),
  },

  watch: {
    selectedConnection() {
      if (this.selectedConnection) {
        this.login.event_connection = this.selectedConnection.value;
      } else {
        this.login.event_connection = null;
      }
    },
  },

  methods: {
    async logar() {
      try {
        this.loading = true;
        this.erros = [];
        this.$Progress.start();

        await this.$store.dispatch("Usuario/logarUsuario", this.login);
        await this.$store.dispatch("Usuario/logaMessages", this.login);

        // const token_messages = this.$ls.get("token_messages");
        // this.$echo.options.auth.headers.Authorization = token_messages;

        const email = this.$ls.get("email");

        if (email && !this.checkbox) {
          this.$ls.remove("email");
        }
        if (this.checkbox) {
          this.$ls.set("email", this.login.email);
        }

        await this.$store.dispatch("Usuario/getUsuario");

        if (this.role == "ADMIN") {
          this.$router.push({ path: "/" });
        } else if (this.role == "STAFF") {
          this.$router.push({ path: "/leads" });
        } else if (this.role == "MERCADO") {
          this.$router.push({ path: "/loja" });
        } else {
          this.$store.dispatch("Usuario/deslogarUsuario").then(() => {
            this.$toast.error("Usuario não autorizado!");
          });
        }
        this.loading = false;
        this.$Progress.finish();
      } catch (error) {
        if (error.response.status === 401) {
          this.erros.push("Dados incorretos, acesso não autorizado");
        }
        this.loading = false;
        this.$Progress.fail();
      }
    },
  },

  mounted() {
    const email = this.$ls.get("email");
    if (email) {
      this.login.email = email;
      this.checkbox = true;
    }

    if (process.env.VUE_APP_SENHA_MICHEL) {
      this.login.senha = process.env.VUE_APP_SENHA_MICHEL;
    }

    // basenodes();
  },
};
</script>

<style lang="scss" scoped>
// .pattern-logo {
// background-color: #eeeeee !important;
// background-image: url("../assets/logo x auxiliar.png") !important;
// background-position: center center;
// background-repeat: no-repeat;
// background-size: 100%;
// object-fit: fill
// }
</style>
